import React, { useState } from "react";
import Feature from "./Feature";
import {
  analytics,
  mint,
  build,
  develope,
  fundraise,
  launch_pro,
} from "../../asset";

const FeatureSlider = () => {
  const [activeTab, setActiveTab] = useState("Develop");

  const tabs = [
    {
      label: "Develop",
      content:
        "Polycuz’s Develop revolutionizes NFT creation, offering AI-assisted and in-house tools for artists and brands to craft standout collections. Simplify your NFT development with tailored, visionary flexibility.",
      imageSrc: develope,
    },
    {
      label: "Mint",
      content:
        "Polycruz's Mint demystifies NFT minting with its intuitive, no-code interface, making it accessible to creators and collectors alike. Experience seamless minting and join the NFT ecosystem effortlessly.",
      imageSrc: mint,
    },
    {
      label: "Build",
      content:
        "Polycruz's Build feature empowers you to forge vibrant communities through engaging landing pages and bounty programs. Foster interaction and support, fueling the success of your NFT projects.",
      imageSrc: build,
    },
    {
      label: "Fundraise",
      content:
        "Polycruz's Genesis fundraise crowdfunding tool enables transparent and secure fundraising within the web3 community, catering especially to game and metaverse projects in need of funding",
      imageSrc: fundraise,
    },
    {
      label: "Launch Pro",
      content:
        "Launch NFT projects professionally with Polycruz's Pro Marketplace, featuring advanced analytics and a user-friendly dashboard. Elevate your NFTs' visibility and success seamlessly.",
      imageSrc: launch_pro,
    },
    {
      label: "Analytics",
      content:
        "Polycruz's analysis tool provides deep insights into the NFT market, consolidating data across platforms for informed decision-making. Optimize your strategy with trends and analytics at your fingertips.",
      imageSrc: analytics,
    },
  ];

  return (
    <div className="bg-black text-white p-4 md:p-6 lg:p-10">
      <p className="text-center text-lg font-bold text-[#A49BFF] mt-10">
      FEATURES
      </p>
      <h1 className="text-center text-xl font-bold mb-10">
        POLYCRUZ FEATURES
      </h1>
      <div className="flex max-md:grid max-md:grid-cols-3 items-start justify-center md:space-x-4 gap-2 mb-8">
        {tabs.map((tab) => (
          <button
            key={tab.label}
            onClick={() => setActiveTab(tab.label)}
            className={`py-2 md:px-4 lg:px-6 md:py-2 lg:py-2 text-sm rounded-full ${
              activeTab === tab.label
                ? "text-white border border-2 border-purple-800 bg-[#393554]"
                : "border border-2 border-gray-700"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Feature Display */}
      {tabs.map((tab) =>
        activeTab === tab.label ? (
          <Feature
            key={tab.label}
            title={tab.label}
            description={tab.content}
            imageSrc={tab.imageSrc}
          />
        ) : null
      )}
    </div>
  );
};

export default FeatureSlider;
