// WaitListUsers.ts

export type WaitListUser = {
    [address: string]: string;
  };
  
  export const WaitListUsers: WaitListUser = {

        "0x8E9ABBF2529f38093d3F14a45dC5C73079d3f049": "Garvit",
        "0x48a22058710f18a954fC1cAd8Da4fDeC2D593B67": "Om",
        "0xf3000182bB301B26244eA30Ae3BF3961630aE33c": "pS  xao lin nghia",
        "0xee9ca24fb62bfc021e1a46e09e1c1cbecd3341b5": "Matsuri",
        "0x43d53a479c8a3d4a2b5d7b88c1706606be94ed3b": "Smoke9999",
        "0x813e372eb82008b31503027aaacddbd5bd228f89": "FirePunch",
        "0xe46c1e16918a0768df3feea3f15124809c981c41": "miju",
        "0xB6ad3ad1584aCDB8B35a751Cb885fb4dbAb6086B": "az4yemite.bnb",
        "0x36e9ecc0ab3762fc58fc7b9afdb8721f8eeb52f5": "Danylo",
        "0xcc74729d9e099b29b842473f995bf69f7dab2994": "あつし",
        "0xf456141b8fbdbe7b5417e9f5e3adc1c11cac8481": "dirtyraspberry",
        "0x04e20067bc7cfd1bd270c1705d962dcdb7dba5dc": "LightningRTX",
        "0x332466722db627571ea3c56be2448bb1066b0f33": "Zhul171",
        "0x11d9ac587f556fc2565ee1635aa35f74d50e57c4": "Tyorex",
        "0xbed674e247651b231613865f9b4763a0b5a3b16d": "Denii",
        "0x33906aebae3ab9e8d15cd90953f34bd4232cdfb5": "DemonSlayer",
        "0x4bc15a127c002435205bc2be033db3327882bd8a": "AlexBel31",
        "0x308e24cb0c3b0bb35e8e41ba7c865900dc52e71e": "sck668",
        "0xdde316729507164527bb3add47f7a668fa830db9": "unethicalokma",
        "0x13f622cb1df42e80d1c5c69980b9c0c006c2b8c9": "Gatawa",
        "0x3f17502da0ac0235ed5ca05b621e73bfcb0a9b0e": "QG Huynh Diem",
        "0x7730dd55ebbebab7f5e782dfce4f9e48db351c98": "Bitbull",
        "0xcee057506251a8bfdf6c80432da4ed9948a2b31e": "Praven kumar",
        "0x6b0627163ca29d665a9f4bf865f794394b177c1d": "sickterluca",
        "0xa884deb7e52522201118058a8981a5a18d99f60c": "sobirin",
        "0x983A8Dc18b366A772CD4219e882Cd8C4c4a5db95": "Ashokkumar94",
        "0x20b278b9a6715fce84f39bb58382c956cb5c391d": "Luke12",
        "0xad144d3b1b2367987652e68d1a350236510db858": "zzzon",
        "0xeb8cfb283c51b37e415dea27a5fecfdc06303efa": "MaxMayster| Sui Global",
        "0x8cf90ab09800550adccbfac34aae7caf42c8b461": "udom",
        "0x07ee4dc08567e532a4a3ae278dff03ea98dcf0b8": "",
        "0x39f88b7bda0e0d935ca6a34a49e8c29e72fbbd27": "Snip",
        "0xa965b073b927eb61cad2a0a188ca34364b10c493": "ougbuvyt",
        "0xcafda84067bddb0705c27ae0817c2abc1b83b1ce": "zubcocos",
        "0x25e58696fbc8eb88301ee06a57f77d9f0715ec97": "yoon244",
        "0xc84fd1109f769e1c05852df678da5ed015109993": "ToobaSam",
        "0x480a0e29f15e19dbb8075be9540a9ee841bf68cb": "anonimo0345",
        "0xaff38c63537704222877137e69092e08de230ad3": "JeDy",
        "0x47696afe378e980e47cb6a8940c6ec3eb9905419": "shaga",
        "0x5042B80e21Ec03371A1d833b6A63A5ad0b2EF191": "MCS20",
        "0x56251b18fa1f462ef7f891819e8d389e602b19b0": "けい",
        "0x7d8670aef6fa9ecd5d9652cd540375568f34eabf": "Basa",
        "0x64655254eab0b57f40fa8042e457a5f15a79a1a8": "andresaton",
        "0xc74c7c6f97c10948e618661bed79eb0b55c6bf8a": "Bonez",
        "0xd2632726b22fb9119218cb43b06662de1438d722": "Sandman",
        "0xa164997edfd5509086fb3bc7ed7988f708378d0f": "snazzy | We3Camper",
        "0xC4944c9CBD30C31d2B9085A7037dB6b4e6941578": "bes999888",
        "0x58637112d07e32ab86208e36b1e81463e9c64e60": "bouhmid",
        "0x5c3725adce08ef12f8c8da5fff45d2593c0bf4c0": "yunyun",
        "0x7bdda2d09e12f41ff1a498a18d4237a386a56177": "Davielovesgravy",
        "0x035aa06d71596796888eefb158ca7e473dc0fdbc": "お米３",
        "0x386aC324d1c7877922B430bb589528aE65435c3b": "Vit | mTrader",
        "0xb4449f90fe785f1c764de2eb3be86204ebd0c312": "aceem_X",
        "0xcc32d26c3b67b204b986b37d5680769bd44255a8": "hosein.akmTrader",
        "0xca99d35880ce5f6c62ebdc82bdaa02a8ea96101f": "GypsyTano",
        "0x7AF3b0434bc8c52B514cab9476593d41066c72AA": "srkan681",
        "0x431184fcc6f08f1c886e3864085d574012ae1262": "marble_cryptoNL",
        "0x6a2aa012d4684cae6ab8d1ddc1665f6e36772df4": "Okayconcept",
        "0x05cf6d9751ed20b716c7df08ccebb5a9e6f76006": "NICCINICCI",
        "0xC6886591c17B6B44Aded5Ea9f084e037fFc245e8": "Amotalebi",
        "0xefe03b506d55d46ed34463feb185bb508cd7d396": "VAN TAO",
        "0x1a8dcfdacec6fdbe092e63b57c6ec3608d2b4095": "Setharpit210",
        "0xd0538be89b7fe61eeaf2020d6fa663df6a254dc2": "Nmoni",
        "0xa3e545daf1d6f1c3fb2cb0cbdae64bc56c7247c2": "Dreamer",
        "0xFE8ed88928F07D23515B7d4Cf0944c4F613269eb": "anhhai2211",
        "0x5d2524fdef10091ea957fa8602c665e649f3346c": "rayhanbd",
        "0x67925f0c3498bb35e73d2ddc804ce5b64523fb21": "crypto fire",
        "0x763a736f3603dd0b6b18655fb8f17789168014e6": "Anjli",
        "0x72a01b1d35be74f3d8c18ae37c23489bb64babc4": "Goldy",
        "0x4A29B652ee994551A45fC7bD84a25BC2733f2544": "dinex99",
        "0x0b9ea9cc8850ed81d7b5ed997eb3c3391bc4a91a": "Syphax_Trading",
        "0x7d6ef2887f795210fdb4df7eddbd35d08c7450e0": "mariusz36",
        "0xAFA75F44cA4df89E0914f805080b3bEDcc390F43": "samadorbit",
        "0xb02f1394e58639186533581d0a3a5ff307e38a4b": "subhanchachu",
        "0x217fbd4722347d9071415040ef887ec80fd8f982": "khunaungmin",
        "0x5e2352c44ccec4c9ac11a0ea656d82f5f4936090": "KhunZoro",
        "0x02d4bfca7f6a4a3cfc67949ac7f4f8cdbb8830d7": "trantuan2804",
        "0x3e88ad1cbb070fb38f81c4fa2e1ad476d6f1b53c": "Xargas (Lhilith)",
        "0x03C266bCA7bDD7e8debe6806612C1B09502832a0": "Rastin",
        "0x0b7fb5199aab1dd1ca232d1df13371c13f4ff626": "bayanaa",
        "0x27d47cde10b53e0aecbbe3f897e275b8a75a4df4": "burjupol",
        "0x478e35Fd04cB93D50869De699D15a1747E452f92": "WiraR88",
        "0x258a7f849242825240dc1998d508d7ed0acafc6c": "Pawel92",
        "0xe465b807c1dD2f2A362bf290A60Dd51d8D148FEB": "Srkn_gk",
        "0x2339eda6bb5bfa477873fa937796bb6a696a8bef": "yume",
        "0xca2ac8990e54e25e68d44b7f3deb9351e03a3c1b": "FEBRUARY2023",
        "0xbed5a0d0cd430fa49f3e12f343914410a85285e7": "Nazgulim",
        "0x4d1444c369e03fc4ab2d436aabae22508607dc51": "KN.0909",
        "0xca3a34ac320fc0806223c95b6be1895243afac08": "jainudin",
        "0x4E834AB70a9af95dDEE30dA1dAc1a95C92c34C8c": "ram009",
        "0xe336cd08f42d0d6b9fe1b6e84c89d0aba22a702e": "zhimin",
        "0x9deDD2A9BE15067ADccA1b320Bb1d38B6D9119Da": "meskeer",
        "0x6586a8a85cf661b0baef2a40169d19df7b308a67": "rabin1992",
        "0x8D65dc07aAd95C2906D3A1FD6C29153602EE09A6": "sutikno",
        "0x4fa0fF6c86C9835dE2f7f7531e1914A47c089e17": "Ranendra99",
        "0xf060882d73919417cbe691d7089a2d6271d229d8": "vitbag",
        "0x67fe480cdfa245E447ACDeA0A69e164245BD6717": "DPRI Lagi",
        "0x5A2e7F138FfbaDF56F4AF2E835D381074c3149cB": "Alic519",
        "0x869de65a14a6aaccfefaae816a93a98310a48963": "peymangrv",
        "0x67fbbc92c4845ea9f126d922e99b80243e5cf196": "khunhtet",
        "0xb21381aa36ed58d4a47ab1937c9a540bd55b7839": "peyman3248",
        "0xcbaa4e5fc16c22b1afd0d43a74754cd0d4c19c2c": "Xenter",
        "0x47b4515d4c5ff40da0580c91a6d9d7fa73ff14c8": "ozkan0519",
        "0x28592f29e5a426bd4fb1377662bfa29820222caf": "ranko160",
        "0x2a5c377bdf423658a4cd4a00c4e4be8e80543b3d": "samadorbit",
        "0xc1545b0f9bd5882824aabe72bd322bd46c492f7e": "Anrif",
        "0xc1eE5e4b5664EE9eacb8902B8Fcc333f52E2c3B0": "ozkan519519",
        "0xe41cc5edf85ffd0ca91612929e6e4765cd2367e8": "fanky",
        "0xd77798f14963840dedc66d1565ad241e501ee928": "gaffybay",
        "0x54fff0f4e0ad1c3dfe02e01e3b8bf5788a6c885b": "raketa",
        "0xedf33313d5612aa7b582e03510b529abc1c038a7": "kiwime",
        "0x2106fb0035de44adbad229a002e6aea908ae52f0": "chamdatchua09",
        "0x09d1a13f60b8833d304b24f462b86a56b0c3e616": "YAZKIZIM",
        "0x21df47c259ce48e8bc45973fb223b6cc2d47da98": "Amir2027",
        "0x4aa3ea7373373f9e1adfe741e2a2e13cd003761b": "AungKo",
        "0x362ce7c67ada014e6809ceda93284f5d31b8e209": "Aungmin",
        "0x3a4dc93661069629c3010df6c9aeb1be289482e8": "bangbara",
        "0x3be947f1a529fe4341c85d9546c4d1d8efe56e44": "Saga",
        "0xa2C3754939C131fc8E068EE6a9b0E019078894bc": "Hancock",
        "0xc825862C364647681Ab9b95B08fE80aB3aF6c8cd": "Hanzo14",
        "0x5c16efe3388a1137f10578b5470f31e556668cf7": "Azoro",
        "0x047ab239ba13076c9e220ad8606d1c4915bff77f": "kimosho",
        "0xfb404c547e4efe0be20790f36989ea598fc3cdff": "Roman777.x |Bay Swap",
        "0x0f0afa9a440b725420e34c7db7b9e41a96dfe848": "taroo8",
        "0xef4e1e4b3baa9cfc4e9c5a17a4f2aef285d543f2": "loveyoumg3000",
        "0x3e1034a215430383403da056a384baaba716e01a": "Hook.bnb",
        "0x3d0fceb8c90c2c8700b695824cfe753fecd8b934": "Noobie",
        "0x3ef92159e702c8a7ce477213f00135e388e7a84e": "AroeyMarussy",
        "0x4d523c8f1cd685fd51f3e1d114eecbb7619c342e": "kryptozielina",
        "0x84A17d1fAc27AD0AA926A3fbFF34419B3FaF7092": "hhhhhhhh",
        "0x58c9bbb0c094ac4cf5224154e20e9f76542468a2": "Oleksandr",
        "0x02c1C8eAa50A4a67EEfdD505bF3E81444c946A20": "RezaML",
        "0xca5d0922323eba5959462684375b1be64d77db73": "DuitSantai",
        "0xf1b2dad9cd077639ec2ca2bb5f7d270d5e1e90be": "Tewi_Air",
        "0x3520c166edbf2d99946dc5bdd593a25a9a5aede8": "Donkey",
        "0x13921be51f7e5f4a5c3765304444de678f05bb66": "Duchoang",
        "0xc8e7ef14a499958ebcfa77fe113b091712cc84b4": "afri",
        "0xfdd9b347ea2c32a039cc52299f6be64bb459bc4b": "helloael_",
        "0xd7b0282fed6400c99187166caf4ea32ec76f3855": "Windy",
        "0xa94ad810c917ed3e5b65ac5b607aaa6dc4f6f2aa": "Jeffibasu",
        "0x72c547cc6b936ed44de6caacad6abf4b792f641e": "Pranavappu",
        "0xbd2efdc4718777726a95dca5c639ad901961369": "wjefoerfjgr",
        "0xCeE057506251a8BFDF6C80432DA4eD9948a2B31E": "Praven kumar",
        "0xa965B073B927eB61cAD2a0a188ca34364b10c493": "Aigars",
        "0xaff38c63537704222877137E69092E08dE230ad3": "Denis",
        "0x116A6a30581c99fF833E6C516eeEc23Acd2a2dD5": "Shailendra Kumar ",
        "0x547606aD8c084555B1AF60b3cD2283EDaf714709": "Purvii",
        "0xB3dB8d23a98780002b10Da5Fb082e47b2D22e4F3": "Yeo Kaixiang",
        "0x698F52fC8156399c5691377365D643F85217BE33": "Taeseong Park",
        "0x43D53A479C8a3d4A2b5D7B88c1706606BE94eD3B": "Adriano Xavier",
        "0x5b8B87331E484afb35138DA956aa30Be26c1f22F": "gaurav dua",
        "0x006bfA2c942c0A717098c445664e1dc1EC6177d0": "Rajni Sultania",
        "0x098d0C50dC2df6E8E298D4D35D4234c33BC31f91": "megha dua",
        "0x332466722db627571Ea3C56bE2448bb1066b0f33": "ZULKIFLI",
        "0x3ef92159e702c8A7CE477213F00135E388E7a84E": "Yenita",
        "0xa94AD810c917Ed3e5B65Ac5B607aaa6Dc4F6F2AA": "Jefenon Basumatary",
        "0xB95ef940Ae7dD2b9A3f6f49A3C8eD5E75191f1a6": "helloael",
        "0xd7b0282fEd6400c99187166Caf4eA32ec76f3855": "windy",
        "0x3d0fcEb8c90C2c8700b695824cfe753fecD8b934": "Sachin",
        "0x725bf22A71BaB96057515106c244E336a3Ff5b53": "noir",
        "0x343a567B6f94cda269bE3F6489cb0c9524E3F1A1": "Robert",
        "0xC84FD1109f769e1C05852Df678Da5ED015109993": "SmartCrypto",
        "0xf7e2a8Ce71D28Bb7Afde18EC6CD42Bf3e9Ae165A": "Tooba",
        "0x0ea6202B1F4D0Da0eAD6Bb6A0A45ba54432521f4": "Stephan",
        "0x85785205d2121cc2020CfBB89Bcc8C666F4B3e98": "inamsubhan",
        "0x8820115D5c8c7F5995346b46886aDB1723726E52": "Haider",
        "0xd14c1f97eda84a43c7e8E4deEd31D8b0cCFF7100": "John james",
        "0xEFe03b506D55D46Ed34463feb185Bb508Cd7D396": "Van Tao",
        "0x8371ddeBeCD729Bfb14e4aB7D4aB2bAaDF79A4BF": "Angelou colina",
        "0x9eFEb581C020Fc746C1df51d8d2e829C03aA6674": "Chris Catalano ",
        "0x13F622Cb1Df42e80d1C5c69980B9c0C006C2B8C9": "Idris Gatawa",
        "0xA0bdcF72FC0c0eA37EA898a4F8D261E2641C0cA1": "Rayan",
        "0xc0e8d01437f006c36e3d7de64a04602054794205": "Younita",
        "0xcc527AA2A51Db701B3D1aDfAf0a79535be787d2c": "Mitesh",
        "0xA5A6417c81Ff1C047a13c68d0eedE21253A0dCef": "nik_icloud",
        "0xd7e2a9955d41e02d7132f5e51e15a2b297ea2efa": "tianjun020930",
        "0x3e9a240adf70a4e7863b453bbd2b1718c193d6c2": "jurantchen1972",
        "0x55ca3dc8696cd0a4beb010562e7c31078bd484da": "jurantchen",
        "0x72aB7E948a21Ed5Ce511e3432435E3046eF5BD84": "Clasix00",
        "0x6ac9a0b04baf70f2f6f6566fd17bcaec0cf764da": "iamuseless2722",
        "0x77743a956E271c7A9D9d79DF05C3B8372533f62A": "BryanChen77",
        "0x81a2f419c400329bfcab2aadbcd60600c42d8019": "ALIENTWEET3",
        "0xeeF6c3dF68bcb03f149956515d6CfDEBf233D60D": "direitaslz",
        "0x7970368c6eff1b696df06bc0cfc23d7d05819be6": "solided9",
        "0x1c3a9b0c62e15a923afdb18eb6c1db200c79027d": "GrienRizen",
        "0xffa577a214db70dd173113f6f095effe9d9ce664": "grzelanna",
        "0x1a53a2c61c9d4aed9fd7ff1539b50a66f7d78bf7": "Cat1fu",
        "0xaab3b31b0eabd268fbda416910b230f23a945f2b": "rn3019",
        "0x84F1548FB24C5334eCB2b4737F570325C8087C74": "Vijay72806778",
        "0xc6924e9097ace9633109503aeef112417e66986f": "GhulamR17720867",
        "0x02667c1f9965c95b1954d44127d37852b020cc4e": "diamondsky53335",
        "0xa0bdcf72fc0c0ea37ea898a4f8d261e2641c0ca1": "afin_win",
        "0x3bca75056bcb6f148e0335ddce092fd4f7088f21": "kinzaqoqi",
        "0x9a5e6066233b4a758184a77ac7846ab74c1e4d22": "LeopardToan",
        "0x45a31d6d6665b338f088ebc4addd252941371501": "DrShahriarTare1",
        "0x136e744b1a2a2816b98556de69c636dd35e65306": "bml859",
        "0x2e4fa0a4aee1456f9028545e186d30ff407fb8f3": "mrduyhlu",
        "0x227257d02c331b5b3bd51558d8dbb78dc2f80041": "AllCryptosNFT",
        "0xb603Fb39D3d17b750962C729bb441651706Baf7E": "GoogleG14920470",
        "0xf4545cb51f89cd9a60fd99fd134c74f9953c862e": "cryto219",
        "0x085b2d301b2066e794da58d6cfecad8b766d5c72": "phamducphuong11",
        "0xac92d6db8938fb35bcf9d454db7741ab3e42b02d": "DiantoW4hyu",
        "0xe69453bd9cf83f7a3d12b5baa2e555d76a0c8947": "0xairdrop3",
        "0xd76cc7084f05d54b0c5a29681b2f5aa00be754b0": "robby_shate",
        "0xaE6473362fD645703374f58557722932fC5376DA": "SalimHaidar13",
        "0x8517f8952bb2bcf446c0e75a99d962540168b6ab": "Komeonaka",
        "0xD02701Ab65782b1De5687ba1B4AE4909aC2CaF56": "aaliyev123",
        "0x06dd52564b68dde77b4e9d4e0e5d325f9a86746d": "Schick73467",
        "0xd3cdb73f8ae4a777c136020ee4318c6ebce24d6b": "thetpystory",
        "0x978b4e270921c0d4763557d8c56e7825253af1b4": "ManaDuniya",
        "0x794d60f4c9b48e58e5e2a120964cbfac83dec590": "tendysatriya",
        "0xdf17e92b3234b63655371bf75fcfd07bad289ddb": "Sagor11547095",
        "0xDF767CcB69648E9d86A58Ae55B35892D9a9321BF": "Vimal83208653",
        "0x478cd7f673bac590e35cf1f7b26292bb206ae207": "Trmo1995",
        "0x553ae530a8d116ed4d79e2bdc854646577131a77": "VeraAnggi9",
        "0x4fc29cc44caa5b9fc4879ad0c0f34658363bbc43": "abreqadhabra",
        "0x6895845A3a6Be201Bd760Bc787F67C6036a75D9F": "",
        "0x7c1fbf049a2243ae94c2b6bf0d3ca35d1b06a05a": "vanyasem",
        "0x9c392f2018765d96c037baff307fd57b3dc07c02": "laluong08111",
        "0x48416f47704d1d069389ef9b9c2fd0f3a2e37ce5": "soheily54sina",
        "0x7718d3b51c5c4b0a18f70466307ed82ac195f9ad": "FreeCrypCoin",
        "0x75f15c8e9ac9035ba69380eb52ea96ad85117f46": "mogaton",
        "0x0ae20f1896cd62a25178e26045fed0bdd7de58e7": "a316299742",
        "0x2c1a2ebc1623679f29b340b102f0a18a37b602f8": "Kirsbae520",
        "0x75e2a63965af980f6777bc4ecaed49f6f33db698": "abdelfa96076881",
        "0x5792500e2A87607F1240925Bd95711E970920E8d": "mavricksands",
        "0xe7970d30c33cf916542b52f69bdf94c3294741db": "ChowdaryTharish",
        "0x3407329001fcd247a662bd5ac273cf149dbd918b": "bedwalm",
        "0xe0e6d3ad64b084ec7273e011c5a10d616dad6580": "Manik13928534",
        "0x84ecc4613b81bc3465c08cb8289660cfaca89e43": "javisito714",
        "0xc532dad98453ab086af170c42746001b8aaade0a": "0XYEY",
        "0xac90d0f650475db876d4cd95595bdb8d04370044": "Jutinogo",
        "0xeddbf223e97b039061bfe74de849df1deacbb652": "king47497300",
        "0xae19e1eb6b0aef48670fbeb594e4eba5ccc39044": "DALAO31342479",
        "0x157c0cf764315355223ecd9ae6caf310ff78e084": "AltzcoinGordon",
        "0x5f7071b5f028f71db522b0d855ca908c750b2e36": "AdolfoEzquivel1",
        "0x2c7737a33067de22803d17b2d32014d432fc43f4": "yilmaztc42",
        "0x396946176D8e166bdeDd9Bbdd38D342Dc47e4613": "NabeelMughal07",
        "0x6b6336994fb744b077599498e041884958a7639a": "nonyanliu",
        "0x464cf4b9ebea304da0715738d7521dea7977ddbc": "Grenzenloser1",
        "0xfb269f705e2233bfc840c82d25501936d8e647ae": "ritz879",
        "0xc9f2a96e2b964f4c7d7469241f0ccf50ef42ef28": "SudestCrypto",
        "0x98b55cfc18194b323d254327e8eafcb3a0742e90": "lijunwa88628235",
        "0x99e848b9f10efe99708f08342e060550069c8b23": "uttitho1",
        "0x76fc4B8b47969c14AD556e1Cb56D4e4C1E17C545": "kemans63",
        "0x0ad8a3fdd123ef21ccccb6433bc555f67f154229": "xtutasd",
        "0x0e9d1d7fea7032252c07b78fd9642ea77a4b5103": "a_masrawwi",
        "0x978ce43995165a3fc485678df69efb84bcd39c8b": "GameBuz123",
        "0xd248a632f74440b1869b33954b92abad9bbd9be5": "VasilevaBL",
        "0x346a7b632f7b31438f262f5b897944eb7f451fe0": "mukhalim_khalim",
        "0xefd5ca736cdeff843e16462ed427e66c6e474e77": "Benedit83917539",
        "0x26ef68ce05f19f76299b7a37fccfadaa0b808719": "OussZack",
      
     };
    