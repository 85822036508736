import React from "react";
import {
  linkedIn,
  team_image_10,
  team_image_11,
  team_image_12,
} from "../../asset";

interface TeamMember {
  name: string;
  title: string;
  description: string;
  imgSrc: string;
  linkedInUrl: string;
}

const teamMembers: TeamMember[] = [
  {
    name: "EVGEN",
    title: "Advisor",
    description:
      "Evgen Verzun, distinguished in technology and cybersecurity, is an inventor, serial entrepreneur, and founder of Kaizen.Finance, with broad expertise and an advisory role at Polycruz.",
    imgSrc: team_image_11,
    linkedInUrl: "https://www.linkedin.com/in/verzun/",
  },
  {
    name: "MICHAEL",
    title: "Advisor",
    description:
      "Michael Skrychevsky, a web3 pioneer, leads partnerships at IguVerse, co-founds Chatbank.pro, heads CoinyPay AI, and advises Polycruz, driving innovation at the blockchain-AI nexus.",
    imgSrc: team_image_12,
    linkedInUrl: "https://www.linkedin.com/in/mskylion/",
  },
  {
    name: "DENIS",
    title: "Advisor",
    description:
      "Denis Smalianyi, a crypto and blockchain expert, co-founds ScottyBeam.io, serves as Crypto Head at Hypersphere AI, and advises Polycruz.",
    imgSrc: team_image_10,
    linkedInUrl: "https://www.linkedin.com/in/smalianyi/",
  },
];

const TopTeam: React.FC = () => {
  return (
    <section className="bg-[#0d0d0d] pb-20">
      <div className="mx-5 md:mx-10 md:px-40">
        <h2 className="text-white font-bold text-xl text-center mb-14">
          ADVISORS
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-black rounded-2xl shadow-lg p-6 text-white relative"
            >
              <div className="flex items-center mb-6">
                <img
                  src={member.imgSrc}
                  alt={member.name}
                  className="w-16 h-16 rounded-full mr-4"
                />
                <div>
                  <h3 className="text-xl font-bold">{member.name}</h3>
                  <p className="text-sm text-[#A4A6B3]">{member.title}</p>
                </div>
                <a
                  href={member.linkedInUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="absolute top-4 right-4"
                >
                  <img src={linkedIn} alt="LinkedIn" className="w-8 h-8" />
                </a>
              </div>
              <p className="text-sm text-[#A4A6B3] leading-relaxed">
                {member.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TopTeam;
