import React from "react";
import {
  right,
  rwa,
  education,
  entertainment,
  gamefi,
  event,
  metaverse,
  brand,
} from "../asset";

const usecases = [
  {
    title: "Real-World Assets (RWA)",
    description:
      "Assets creation, Tokenization of assets for fractional ownership and transferability. Enable automating property sales and rental agreements with smart contracts. Managing and tracking physical assets using NFTs. Ensuring liquidity through marketplaces for tokenized assets",
    icon: rwa,
  },
  {
    title: "GameFi",
    description:
      "Enabling in-game asset creation and trading as NFTs. Facilitating cross-game interoperability. Find all trending games & players analytics. Engage in game players with AMA, chatting, rewards.",
    icon: gamefi,
  },
  {
    title: "Metaverse",
    description:
      "Tokenizing virtual real estate for buying, selling, and development. Managing digital identities and access with NFTs. Enabling to open metaverse event, and engagement. Facilitating virtual goods and services trade.",
    icon: metaverse,
  },
  {
    title: "IP Brands",
    description:
      "Creating and managing digital collections for authenticity and rarity. Developing interactive experiences and exclusive content. Automating royalty distribution with smart contracts. Preventing counterfeiting through blockchain verification.",
    icon: brand,
  },
  {
    title: "DePIN",
    description:
      "Tokenizing ownership and governance of physical infrastructure. Managing resource allocation with NFTs. Tracking maintenance and upgrades using blockchain. Implementing incentive structures for contributors and participants.",
    icon: brand,
  },
  {
    title: "Entertainment",
    description:
      "NFT-based content distribution and monetization. Exclusive fan experiences and memorabilia. Digital rights management and revenue sharing. Virtual event tickets and interactive experiences.",
    icon: entertainment,
  },
  {
    title: "Education",
    description:
      "Tokenized credentials and certificates. Secure distribution of educational materials. Incentivized learning through NFT rewards. Verification of academic achievement and qualifications.",
    icon: education,
  },
  {
    title: "Events",
    description:
      "Tokenized tickets for secure entry. Exclusive content and memorabilia. Virtual event access and experiences. Enhanced engagement through NFT-based rewards.",
    icon: event,
  },
];

const formatDescription = (description: string) => {
  return description
    .split(". ") // Split the string at each period followed by a space.
    .map((sentence, index) => `This ${sentence.trim()}.`) // Add "This" and remove extra spaces.
    .filter(Boolean); // Remove any empty strings.
};

const UsecaseCard = () => {
  return (
    <div className="bg-[#0d0d0d] text-white min-h-screen md:p-8 flex flex-col items-center md:pt-16 mb-18">
    <p className="w-full max-w-7xl text-md font-bold text-[#A49BFF] mt-10 ml-6 text-start bg-[#0d0d0d]">
      BUILD WITH POLYCRUZ
    </p>
    <h1 className="bg-[#0d0d0d] w-full max-w-7xl text-xl font-bold text-white mt-2 mb-12 ml-6 text-start">
      POLYCRUZ USECASES
    </h1>
    <div className="w-full bg-[#0d0d0d] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 px-4 max-w-7xl">
      {usecases.map((usecase, index) => (
        <div
          key={index}
          className="bg-[#141416] p-6 rounded-lg shadow-lg border border-gray-700 hover:border-purple-500 hover:bg-[#393554] transition-all duration-300 ease-in-out"
        >
          <div className="mb-4 bg-transparent ">
            <img
              src={usecase.icon}
              alt="icons"
              className="bg-[#A49BFF33] p-1.5 rounded-full"
              height={36}
              width={36}
            />
            <h1 className="text-2xl font-bold">{usecase.title}</h1>
          </div>
          {/* Render formatted description */}
          <div className="space-y-1">
            {formatDescription(usecase.description).map((line, idx) => (
              <p key={idx} className="text-sm flex items-start gap-1">
                <img src={right} alt="" className="mt-1.5" />
                {line}
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
  
  );
};

export default UsecaseCard;
