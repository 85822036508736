import React, { useState } from "react";
import { BrowserProvider } from 'ethers';
import { hero1, hero2 } from "../asset";
import { WaitListUsers } from '../components/Navbar/WaitListUsers';

declare global {
  interface Window {
    ethereum?: any;
  }
}

const Hero: React.FC = () => {
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const connectWallet = async (): Promise<void> => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);
        
        if (address in WaitListUsers) {
          window.location.href = 'https://analytic.polycruz.io/';
        } else {
          setShowModal(true);
        }
      } catch (error) {
        console.error('Failed to connect wallet:', error);
      }
    } else {
      alert('Please install MetaMask to connect your wallet');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      console.log('Submitting:', { name, email, wallet: walletAddress });
      alert('Thank you for joining the waitlist!');
      setShowModal(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to join the waitlist. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const Modal: React.FC<{ onClose: () => void }> = ({ onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-8 rounded-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-white">Join the Waitlist</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-white">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-black"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="wallet" className="block text-sm font-medium text-white">Wallet Address</label>
            <input
              type="text"
              id="wallet"
              value={walletAddress}
              readOnly
              className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-black"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-white">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-black"
            />
          </div>
          <div className="flex justify-between">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition disabled:opacity-50"
            >
              {isSubmitting ? 'Submitting...' : 'Join the Waitlist'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return (
    <section className="bg-black text-white flex flex-col md:flex-row items-center justify-center p-8 lg:pl-12 lg:mt-[4rem] xl:mt-[8rem]">
      <div className="md:w-1/2 flex flex-col items-start pl-5">
        <div className="bg-gray-800 text-sm font-semibold text-[#A49BFF] px-4 py-2 rounded-full mb-4 flex items-center gap-2">
          <span className="bg-[#A49BFF] h-1.5 w-1.5 rounded-full"></span>
          Polycruz is now available
          <span className="bg-[#A49BFF] h-1.5 w-1.5 rounded-full"></span>
        </div>
        <h1 className="md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4">
          Powering Your Digital{" "}
          <br/>
          <span className="italic font-thin">Asset Journey</span>
        </h1>
        <p className="text-lg text-gray-400 mb-8">
          Polycruz streamlines the entire process, ensuring <br /> success at
          every stage.
        </p>
        <div className="flex space-x-4">
          <button 
            onClick={connectWallet}
            className="bg-indigo-600 text-white px-5 py-2 rounded-md hover:bg-indigo-700 transition"
          >
            Launch Beta
          </button>
        </div>
      </div>
      
      <div className="md:w-1/2 mt-10 md:mt-0 relative hidden md:block">
        <div className="relative">
          <img
            src={hero1}
            alt="Dashboard preview 1"
            className="w-[75%] md:w-[80%] lg:w-[75%]  relative"
            style={{ transform: "translateY(-40%)", zIndex: 5 }}
          />
          <img
            src={hero2}
            alt="Dashboard preview 2"
            className="w-[60%] md:w-[70%] lg:w-[60%] absolute top-0 left-0 transform translate-x-5 -translate-y-5"
            style={{ transform: "translate(50%, 40%)", zIndex: 10 }}
          />
        </div>
      </div>

      {showModal && <Modal onClose={() => setShowModal(false)} />}
    </section>
  );
};

export default Hero;