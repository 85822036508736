import React from "react";
import Navbar from "./components/Navbar/Navbar";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer";

const App = () => {
  return (
    <>
      <Navbar />

      <Home />

      {/* <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/b" element={<Solution />} />
          <Route path="/a" element={<Home />} />
        </Routes>
      </BrowserRouter> */}
      <Footer />
    </>
  );
};

export default App;
